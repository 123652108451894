import React from 'react'
import FormDinamicoCrearProductos from './FormDinamicoCrearProductos'

function CargueProductos() {
  return (
    <div className='container p-3'>
        <FormDinamicoCrearProductos />       
    </div>
  )
}

export default CargueProductos